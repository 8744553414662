import { ApolloError } from '@apollo/client/core';
import LiveChartSnackbar from '@root/util/livechart-snackbar';
import hasOwnProperty from '@root/javascript/util/has-own-property';
/**
 * @param e The error to attempt to handle
 * @returns True when the error has been handled.
 */
export default function (e) {
    if (e instanceof ApolloError) {
        const { networkError } = e;
        if (networkError && hasOwnProperty(networkError, 'statusCode')) {
            switch (networkError.statusCode) {
                case 503:
                    if (networkError.response.headers.has('X-LiveChart-Maintenance')) {
                        LiveChartSnackbar.show({
                            text: 'LiveChart.me is undergoing maintenance',
                            customClass: 'snackbar-error',
                            duration: null,
                        });
                    }
                    return true;
                case 429:
                    LiveChartSnackbar.show({
                        text: "You're being rate-limited, please slow down a little",
                        customClass: 'snackbar-error',
                    });
                    return true;
                case 401:
                    LiveChartSnackbar.show({
                        text: 'Session expired, please reload the page',
                        customClass: 'snackbar-error',
                        duration: null,
                        actionText: 'RELOAD',
                        onActionClick: () => window.location.reload(),
                    });
                    return true;
                default: // do nothing
            }
        }
    }
    return false;
}
