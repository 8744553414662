import CookieBackedPreferences from './cookie-backed-preferences';
const COOKIE_KEY = 'preferences';
var PreferenceKeys;
(function (PreferenceKeys) {
    PreferenceKeys["NightMode"] = "night_mode";
    PreferenceKeys["RevealSpoilers"] = "reveal_spoilers";
    PreferenceKeys["Titles"] = "titles";
    PreferenceKeys["TimeZone"] = "time_zone";
    PreferenceKeys["AutoWebp"] = "auto_webp";
    PreferenceKeys["DefaultReleaseSchedules"] = "default_release_schedules";
})(PreferenceKeys || (PreferenceKeys = {}));
var Titles;
(function (Titles) {
    Titles["English"] = "english";
    Titles["Romaji"] = "romaji";
})(Titles || (Titles = {}));
var Theme;
(function (Theme) {
    Theme["System"] = "system";
    Theme["Dark"] = "dark";
    Theme["Light"] = "light";
})(Theme || (Theme = {}));
export default class default_1 extends CookieBackedPreferences {
    constructor() {
        super(COOKIE_KEY);
    }
    get theme() {
        if (this.has(PreferenceKeys.NightMode)) {
            return this.getTyped(PreferenceKeys.NightMode) ? Theme.Dark : Theme.Light;
        }
        return Theme.System;
    }
    setNightMode(value) {
        if (value === null) {
            this.remove(PreferenceKeys.NightMode);
        }
        else {
            this.set(PreferenceKeys.NightMode, value);
        }
    }
    setTheme(value) {
        if (value === Theme.System) {
            this.remove(PreferenceKeys.NightMode);
        }
        else {
            this.set(PreferenceKeys.NightMode, value === Theme.Dark);
        }
    }
    toggleNightMode(reload) {
        const changed = this.set(PreferenceKeys.NightMode, !this.get(PreferenceKeys.NightMode));
        if (changed && reload) {
            // We're using #assign here since window.location.reload caused issues after the CloudFlare
            // challenge page due to it being a POST request.
            window.location.assign(window.location.href);
        }
        return changed;
    }
    get revealSpoilers() {
        return this.getTyped(PreferenceKeys.RevealSpoilers, true);
    }
    resolveTitle(romajiTitle, englishTitle) {
        let userPreferredTitle = romajiTitle;
        if (this.get(PreferenceKeys.Titles) === Titles.English && englishTitle) {
            userPreferredTitle = englishTitle;
        }
        return userPreferredTitle;
    }
    get defaultReleaseSchedulesEnum() {
        const value = this.get(PreferenceKeys.DefaultReleaseSchedules);
        switch (value) {
            case 'audio_best_match':
            case 'subtitled_best_match':
            case 'earliest_release':
                return value.toUpperCase();
        }
    }
}
default_1.COOKIE_KEY = COOKIE_KEY;
export { PreferenceKeys, Titles, Theme, };
