import CollectionCountdownRunner from '@root/javascript/countdown/collection-countdown-runner';
export default class extends CollectionCountdownRunner {
    computeValue(_element, diff) {
        let value = '';
        // Days
        if (diff.days > 0) {
            value += `${diff.days}d `;
        }
        // Hours
        if (diff.hours > 0) {
            const zpadHours = diff.days > 0;
            value += `${this.zpadIf(diff.hours, zpadHours)}h `;
        }
        // Minutes
        const zpadMins = Math.max(diff.days, diff.hours) > 0;
        value += `${this.zpadIf(diff.minutes, zpadMins)}m `;
        // Seconds
        if (diff.days < 1) {
            value += `${this.zpad(diff.seconds)}s `;
        }
        return value.trimRight();
    }
    updateFinishedItem(element) {
        element.remove();
    }
}
