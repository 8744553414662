var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _BrowserStorageUtils_storageName;
function storageAvailable(storage) {
    try {
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22
            // Firefox
            || e.code === 1014
            // test name field too, because code might not be present
            // everything except Firefox
            || e.name === 'QuotaExceededError'
            // Firefox
            || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
            // acknowledge QuotaExceededError only if there's something already stored
            && storage.length !== 0;
    }
}
var StorageLocationKey;
(function (StorageLocationKey) {
    StorageLocationKey["Local"] = "localStorage";
    StorageLocationKey["Session"] = "sessionStorage";
})(StorageLocationKey || (StorageLocationKey = {}));
const STATIC_INSTANCES = {};
class BrowserStorageUtils {
    static getStaticInstance(storageName) {
        var _a;
        (_a = STATIC_INSTANCES[storageName]) !== null && _a !== void 0 ? _a : (STATIC_INSTANCES[storageName] = new this(storageName));
        return STATIC_INSTANCES[storageName];
    }
    static get localStorage() {
        return this.getStaticInstance(StorageLocationKey.Local);
    }
    static get sessionStorage() {
        return this.getStaticInstance(StorageLocationKey.Session);
    }
    constructor(storageName) {
        _BrowserStorageUtils_storageName.set(this, null);
        __classPrivateFieldSet(this, _BrowserStorageUtils_storageName, storageName, "f");
        this.isSupported = storageAvailable(this.storage);
    }
    get storage() {
        return window[__classPrivateFieldGet(this, _BrowserStorageUtils_storageName, "f")];
    }
    getBoolean(key, defaultValue = null) {
        return this.get(key, defaultValue, true);
    }
    get(key, defaultValue, parseAsJSON = false) {
        if (!this.isSupported) {
            return defaultValue;
        }
        let value = this.storage.getItem(key) || defaultValue;
        if (parseAsJSON && typeof value === 'string') {
            try {
                value = JSON.parse(value);
            }
            catch (e) {
                // do nothing
            }
        }
        return value;
    }
    pop(key, defaultValue, parseAsJSON = false) {
        const value = this.get(key, defaultValue, parseAsJSON);
        this.remove(key);
        return value;
    }
    set(key, value) {
        if (!this.isSupported) {
            return;
        }
        let serializedValue = value;
        if (typeof serializedValue === 'object') {
            serializedValue = JSON.stringify(serializedValue);
        }
        this.storage.setItem(key, serializedValue);
    }
    remove(key) {
        if (this.isSupported) {
            this.storage.removeItem(key);
        }
    }
    key(index) {
        return this.isSupported ? this.storage.key(index) : null;
    }
    has(key) {
        return this.get(key, null) !== null;
    }
    get length() {
        return this.isSupported ? this.storage.length : 0;
    }
}
_BrowserStorageUtils_storageName = new WeakMap();
export default BrowserStorageUtils;
