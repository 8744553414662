import CookieBackedPreferences from './cookie-backed-preferences';
const COOKIE_KEY = 'link_preferences';
var Sites;
(function (Sites) {
    Sites["AniList"] = "anilist";
    Sites["AniDB"] = "anidb";
    Sites["AnimeNewsNetwork"] = "anime_news_network";
    Sites["AnimePlanet"] = "anime_planet";
    Sites["Anisearch"] = "anisearch";
    Sites["Kitsu"] = "kitsu";
    Sites["MyAnimeList"] = "myanimelist";
})(Sites || (Sites = {}));
const DEFAULTS = {
    [Sites.AniList]: true,
    [Sites.AniDB]: true,
    [Sites.MyAnimeList]: true,
    [Sites.AnimePlanet]: true,
    [Sites.Anisearch]: true,
    [Sites.Kitsu]: true,
};
export default class extends CookieBackedPreferences {
    constructor() {
        super(COOKIE_KEY, { defaultValue: DEFAULTS });
    }
}
