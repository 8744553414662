var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _instances, _apply, _renderDate, _renderPrecisionedDate, _renderTimeOrDate, _renderTimeOrDateTime, _renderRelease, _format, _applyDatePrecision;
import { Controller } from '@local-stimulus';
import { DateTime } from 'luxon';
import { formatMillis, TwoDigitNumericLongShortNarrowStyleOptions, TwoDigitNumericStyleOptions, LongShortNarrowStyleOptions, TimeZoneNameStyleOptions, FullLongMediumShortStyleOptions, } from '@javascript/util/datetime-formatter';
import PageConfigManager from '@javascript/util/page-config-manager';
import { hasPrintableFormatOptions } from '@javascript/util/time-utils';
var DatePrecision;
(function (DatePrecision) {
    DatePrecision[DatePrecision["None"] = 0] = "None";
    DatePrecision[DatePrecision["Year"] = 1] = "Year";
    DatePrecision[DatePrecision["MonthYear"] = 2] = "MonthYear";
    DatePrecision[DatePrecision["Full"] = 3] = "Full";
})(DatePrecision || (DatePrecision = {}));
export default class extends Controller {
    constructor() {
        super(...arguments);
        _instances.add(this);
    }
    connect() {
        __classPrivateFieldGet(this, _instances, "m", _apply).call(this);
    }
}
_instances = new WeakSet(), _apply = function _apply() {
    const { data } = this;
    const dateTimeString = data.get('datetime');
    if (!dateTimeString) {
        return;
    }
    switch (data.get('type')) {
        case 'time-or-date':
            __classPrivateFieldGet(this, _instances, "m", _renderTimeOrDate).call(this);
            break;
        case 'time-or-datetime':
            __classPrivateFieldGet(this, _instances, "m", _renderTimeOrDateTime).call(this);
            break;
        case 'precisioned-date':
            __classPrivateFieldGet(this, _instances, "m", _renderPrecisionedDate).call(this);
            break;
        case 'release':
            __classPrivateFieldGet(this, _instances, "m", _renderRelease).call(this);
            break;
        case 'date':
            __classPrivateFieldGet(this, _instances, "m", _renderDate).call(this);
            break;
    }
}, _renderDate = function _renderDate() {
    __classPrivateFieldGet(this, _instances, "m", _format).call(this, ({ dateTime, today, contentFormatOptions, titleFormatOptions, }) => {
        titleFormatOptions.dateStyle = FullLongMediumShortStyleOptions.Medium;
        contentFormatOptions.month = TwoDigitNumericLongShortNarrowStyleOptions.Short;
        contentFormatOptions.day = TwoDigitNumericStyleOptions.Numeric;
        if (dateTime.year !== today.year) {
            contentFormatOptions.year = TwoDigitNumericStyleOptions.Numeric;
        }
    });
}, _renderPrecisionedDate = function _renderPrecisionedDate() {
    __classPrivateFieldGet(this, _instances, "m", _format).call(this, ({ dateTime, today, contentFormatOptions, data, }) => {
        const precision = +data.get('precision');
        contentFormatOptions.timeZone = dateTime.zone.name;
        if (precision === DatePrecision.Full) {
            contentFormatOptions.month = TwoDigitNumericLongShortNarrowStyleOptions.Short;
        }
        else {
            contentFormatOptions.month = TwoDigitNumericLongShortNarrowStyleOptions.Long;
        }
        contentFormatOptions.day = TwoDigitNumericStyleOptions.Numeric;
        contentFormatOptions.year = TwoDigitNumericStyleOptions.Numeric;
        __classPrivateFieldGet(this, _instances, "m", _applyDatePrecision).call(this, contentFormatOptions, precision);
    });
}, _renderTimeOrDate = function _renderTimeOrDate() {
    __classPrivateFieldGet(this, _instances, "m", _format).call(this, ({ dateTime, today, contentFormatOptions, titleFormatOptions, }) => {
        const hourCycle = PageConfigManager.intlHourCycle;
        titleFormatOptions.dateStyle = FullLongMediumShortStyleOptions.Long;
        titleFormatOptions.timeStyle = FullLongMediumShortStyleOptions.Short;
        titleFormatOptions.hourCycle = hourCycle;
        if (dateTime.year === today.year) {
            if (dateTime.month === today.month && dateTime.day === today.day) {
                contentFormatOptions.hour = TwoDigitNumericStyleOptions.Numeric;
                contentFormatOptions.minute = TwoDigitNumericStyleOptions.TwoDigit;
                contentFormatOptions.hourCycle = hourCycle;
            }
            else {
                contentFormatOptions.month = TwoDigitNumericLongShortNarrowStyleOptions.Short;
                contentFormatOptions.day = TwoDigitNumericStyleOptions.Numeric;
            }
        }
        else {
            contentFormatOptions.year = TwoDigitNumericStyleOptions.Numeric;
            contentFormatOptions.month = TwoDigitNumericLongShortNarrowStyleOptions.Numeric;
            contentFormatOptions.day = TwoDigitNumericStyleOptions.Numeric;
        }
    });
}, _renderTimeOrDateTime = function _renderTimeOrDateTime() {
    __classPrivateFieldGet(this, _instances, "m", _format).call(this, ({ dateTime, today, contentFormatOptions, titleFormatOptions, }) => {
        const hourCycle = PageConfigManager.intlHourCycle;
        contentFormatOptions.year = TwoDigitNumericStyleOptions.Numeric;
        contentFormatOptions.month = TwoDigitNumericLongShortNarrowStyleOptions.Short;
        contentFormatOptions.day = TwoDigitNumericStyleOptions.Numeric;
        contentFormatOptions.hour = TwoDigitNumericStyleOptions.Numeric;
        contentFormatOptions.minute = TwoDigitNumericStyleOptions.TwoDigit;
        contentFormatOptions.hourCycle = hourCycle;
        titleFormatOptions.dateStyle = FullLongMediumShortStyleOptions.Long;
        titleFormatOptions.timeStyle = FullLongMediumShortStyleOptions.Short;
        titleFormatOptions.hourCycle = hourCycle;
        titleFormatOptions.timeZoneName = TimeZoneNameStyleOptions.Short;
        const isCurrentYear = dateTime.year === today.year;
        if (isCurrentYear || dateTime.year === today.year + 1) {
            delete contentFormatOptions.year;
            if (isCurrentYear && dateTime.month === today.month && dateTime.day === today.day) {
                delete contentFormatOptions.month;
                delete contentFormatOptions.day;
            }
        }
        else {
            delete contentFormatOptions.hour;
            delete contentFormatOptions.minute;
            delete contentFormatOptions.hourCycle;
        }
    });
}, _renderRelease = function _renderRelease() {
    __classPrivateFieldGet(this, _instances, "m", _format).call(this, ({ dateTime, today, contentFormatOptions, titleFormatOptions, }) => {
        titleFormatOptions.dateStyle = FullLongMediumShortStyleOptions.Medium;
        titleFormatOptions.timeStyle = FullLongMediumShortStyleOptions.Short;
        contentFormatOptions.year = TwoDigitNumericStyleOptions.Numeric;
        contentFormatOptions.month = TwoDigitNumericLongShortNarrowStyleOptions.Short;
        contentFormatOptions.day = TwoDigitNumericStyleOptions.Numeric;
        contentFormatOptions.hour = TwoDigitNumericStyleOptions.Numeric;
        contentFormatOptions.minute = TwoDigitNumericStyleOptions.TwoDigit;
        contentFormatOptions.weekday = LongShortNarrowStyleOptions.Short;
        contentFormatOptions.hourCycle = PageConfigManager.intlHourCycle;
        const sameYear = dateTime.year === today.year;
        const isToday = sameYear && dateTime.month === today.month && dateTime.day === today.day;
        const sameWeek = sameYear && dateTime.weekNumber === today.weekNumber;
        if (sameYear) {
            delete contentFormatOptions.year;
        }
        if (sameWeek) {
            delete contentFormatOptions.month;
            delete contentFormatOptions.day;
        }
        if (isToday) {
            delete contentFormatOptions.weekday;
        }
    });
}, _format = function _format(callback) {
    var _a;
    const { data } = this;
    const dateTimeString = data.get('datetime');
    if (!dateTimeString) {
        return;
    }
    const timeZone = (_a = data.get('timezone')) !== null && _a !== void 0 ? _a : PageConfigManager.get('time-zone');
    const luxonTimeOptions = { zone: timeZone || undefined };
    const dateTime = DateTime.fromISO(dateTimeString, luxonTimeOptions);
    const today = DateTime.fromObject({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
    }, luxonTimeOptions);
    const contentFormatOptions = {
        timeZone: timeZone
    };
    const titleFormatOptions = {
        timeZone: timeZone
    };
    callback({
        dateTime, today, contentFormatOptions, titleFormatOptions, data,
    });
    const dateTimeMillis = dateTime.toMillis();
    const { element } = this;
    if (hasPrintableFormatOptions(contentFormatOptions)) {
        element.textContent = formatMillis(contentFormatOptions, dateTimeMillis);
    }
    else {
        element.textContent = data.get('fallback');
    }
    if (hasPrintableFormatOptions(titleFormatOptions)) {
        element.title = formatMillis(titleFormatOptions, dateTimeMillis);
    }
}, _applyDatePrecision = function _applyDatePrecision(options, precision) {
    if (precision < DatePrecision.Full) {
        delete options.day;
        delete options.weekday;
    }
    if (precision < DatePrecision.MonthYear) {
        delete options.month;
    }
    if (precision < DatePrecision.Year) {
        delete options.year;
        delete options.era;
    }
};
