import '@styles/snackbar';
const DEFAULT_ACTION_TEXT_COLOR = '#3b97fc';
export default class {
    static show(data) {
        if (!data.actionTextColor) {
            // eslint-disable-next-line no-param-reassign
            data.actionTextColor = DEFAULT_ACTION_TEXT_COLOR;
        }
        import(/* webpackChunkName: "snackbar" */ 'node-snackbar').then((Snackbar) => {
            Snackbar.show(data);
        }).catch((error) => {
            console.error(error);
        });
    }
}
