var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _MarkFilters_value;
import { LibraryStatus } from '@root/javascript/library/constants';
const TRUE_VALUE = '1';
const FALSE_VALUE = '2';
export default class MarkFilters {
    constructor(value) {
        _MarkFilters_value.set(this, void 0);
        __classPrivateFieldSet(this, _MarkFilters_value, value, "f");
        this.allIncluded = __classPrivateFieldGet(this, _MarkFilters_value, "f") === '111111111';
        this.allExcluded = __classPrivateFieldGet(this, _MarkFilters_value, "f") === '222222222';
    }
    excluded(status) {
        if (this.allExcluded) {
            return true;
        }
        let index = 0;
        switch (status) {
            case undefined:
            case null:
                index = 0;
                break;
            case LibraryStatus.Completed:
                index = 1;
                break;
            case LibraryStatus.Rewatching:
                index = 2;
                break;
            case LibraryStatus.Watching:
                index = 3;
                break;
            case LibraryStatus.Planning:
                index = 4;
                break;
            case LibraryStatus.Considering:
                index = 5;
                break;
            case LibraryStatus.Paused:
                index = 6;
                break;
            case LibraryStatus.Dropped:
                index = 7;
                break;
            case LibraryStatus.Skipping:
                index = 8;
                break;
        }
        return __classPrivateFieldGet(this, _MarkFilters_value, "f").charAt(index) === FALSE_VALUE;
    }
    included(status) {
        if (this.allIncluded) {
            return true;
        }
        return !this.excluded(status);
    }
}
_MarkFilters_value = new WeakMap();
