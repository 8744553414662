const formatterCache = new Map();
var DateTimeUnit;
(function (DateTimeUnit) {
    DateTimeUnit["Year"] = "year";
    DateTimeUnit["Quarter"] = "quarter";
    DateTimeUnit["Month"] = "month";
    DateTimeUnit["Week"] = "week";
    DateTimeUnit["Day"] = "day";
    DateTimeUnit["Hour"] = "hour";
    DateTimeUnit["Minute"] = "minute";
    DateTimeUnit["Second"] = "second";
    DateTimeUnit["Millisecond"] = "millisecond";
})(DateTimeUnit || (DateTimeUnit = {}));
var TwoDigitNumericLongShortNarrowStyleOptions;
(function (TwoDigitNumericLongShortNarrowStyleOptions) {
    TwoDigitNumericLongShortNarrowStyleOptions["TwoDigit"] = "2-digit";
    TwoDigitNumericLongShortNarrowStyleOptions["Numeric"] = "numeric";
    TwoDigitNumericLongShortNarrowStyleOptions["Long"] = "long";
    TwoDigitNumericLongShortNarrowStyleOptions["Short"] = "short";
    TwoDigitNumericLongShortNarrowStyleOptions["Narrow"] = "narrow";
})(TwoDigitNumericLongShortNarrowStyleOptions || (TwoDigitNumericLongShortNarrowStyleOptions = {}));
var TwoDigitNumericStyleOptions;
(function (TwoDigitNumericStyleOptions) {
    TwoDigitNumericStyleOptions["TwoDigit"] = "2-digit";
    TwoDigitNumericStyleOptions["Numeric"] = "numeric";
})(TwoDigitNumericStyleOptions || (TwoDigitNumericStyleOptions = {}));
var LongShortNarrowStyleOptions;
(function (LongShortNarrowStyleOptions) {
    LongShortNarrowStyleOptions["Long"] = "long";
    LongShortNarrowStyleOptions["Short"] = "short";
    LongShortNarrowStyleOptions["Narrow"] = "narrow";
})(LongShortNarrowStyleOptions || (LongShortNarrowStyleOptions = {}));
var TimeZoneNameStyleOptions;
(function (TimeZoneNameStyleOptions) {
    TimeZoneNameStyleOptions["Long"] = "long";
    TimeZoneNameStyleOptions["Short"] = "short";
    TimeZoneNameStyleOptions["ShortOffset"] = "shortOffset";
    TimeZoneNameStyleOptions["LongOffset"] = "longOffset";
    TimeZoneNameStyleOptions["ShortGeneric"] = "shortGeneric";
    TimeZoneNameStyleOptions["LongGeneric"] = "longGeneric";
})(TimeZoneNameStyleOptions || (TimeZoneNameStyleOptions = {}));
var FullLongMediumShortStyleOptions;
(function (FullLongMediumShortStyleOptions) {
    FullLongMediumShortStyleOptions["Full"] = "full";
    FullLongMediumShortStyleOptions["Long"] = "long";
    FullLongMediumShortStyleOptions["Medium"] = "medium";
    FullLongMediumShortStyleOptions["Short"] = "short";
})(FullLongMediumShortStyleOptions || (FullLongMediumShortStyleOptions = {}));
var HourCycleOptions;
(function (HourCycleOptions) {
    HourCycleOptions["H12"] = "h12";
    HourCycleOptions["H23"] = "h23";
    HourCycleOptions["H11"] = "h11";
    HourCycleOptions["H24"] = "h24";
})(HourCycleOptions || (HourCycleOptions = {}));
function buildCacheKey(locales, options) {
    if (options === undefined) {
        return `${locales}`;
    }
    const parts = [
        locales,
        options.localeMatcher,
        options.weekday,
        options.era,
        options.year,
        options.month,
        options.day,
        options.hour,
        options.minute,
        options.second,
        options.timeZoneName,
        options.formatMatcher,
        options.hour12,
        options.hourCycle,
        options.timeZone,
    ];
    return parts.join(',');
}
export function formatDateTime(options, dateTime) {
    return formatMillis(options, dateTime.toMillis());
}
export function formatMillis(options, dateTimeMillis) {
    return new Intl.DateTimeFormat(undefined, options).format(dateTimeMillis);
}
export function buildDateTimeFormat(locales = undefined, block) {
    const options = {};
    block(options);
    return getFormatter(locales, options);
}
export function getFormatter(locales, options) {
    const cacheKey = buildCacheKey(locales, options);
    const cacheEntry = formatterCache.get(cacheKey);
    if (cacheEntry) {
        return cacheEntry;
    }
    const newInstance = new Intl.DateTimeFormat(locales, options);
    formatterCache.set(cacheKey, newInstance);
    return newInstance;
}
export { DateTimeUnit, HourCycleOptions, TwoDigitNumericLongShortNarrowStyleOptions, TwoDigitNumericStyleOptions, LongShortNarrowStyleOptions, TimeZoneNameStyleOptions, FullLongMediumShortStyleOptions };
