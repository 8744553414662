var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _instances, _languages, _getOrInitializeLanguages, _edit, _a;
import LocalStorageUtils from '@local-storage-utils';
import CookieUtils from '@utils/cookie-utils';
import LanguageFallbacks from '@javascript/generated/language_fallbacks';
const LOCAL_STORAGE_KEY = 'languages';
const COOKIE_KEY = 'lang';
function loadLanguages() {
    if (LocalStorageUtils.has(LOCAL_STORAGE_KEY)) {
        const oldValue = LocalStorageUtils.get(LOCAL_STORAGE_KEY, null, true);
        if (oldValue) {
            CookieUtils.set(COOKIE_KEY, oldValue);
        }
        LocalStorageUtils.remove(LOCAL_STORAGE_KEY);
    }
    const userChosenLanguages = CookieUtils.getTyped(COOKIE_KEY, null);
    if (userChosenLanguages && Array.isArray(userChosenLanguages)) {
        return new Set(userChosenLanguages);
    }
    if (process.env.NODE_ENV === 'development') {
        console.log('Fetching user agent languages');
    }
    const newValue = new Set();
    window.navigator.languages.forEach((language) => newValue.add(language.toLowerCase()));
    newValue.forEach((languageCode) => {
        if (Object.prototype.hasOwnProperty.call(LanguageFallbacks, languageCode)) {
            LanguageFallbacks[languageCode].forEach((language) => newValue.add(language));
        }
    });
    return newValue;
}
export default new (_a = class {
        constructor() {
            _instances.add(this);
            _languages.set(this, void 0);
        }
        getAll() {
            return __classPrivateFieldGet(this, _instances, "m", _getOrInitializeLanguages).call(this);
        }
        add(languageCode) {
            __classPrivateFieldGet(this, _instances, "m", _edit).call(this, (languages) => {
                languages.add(languageCode.toLowerCase());
            });
        }
        set(newLanguageCodes) {
            __classPrivateFieldGet(this, _instances, "m", _edit).call(this, (languages) => {
                languages.clear();
                newLanguageCodes.forEach((code) => {
                    languages.add(code.toLowerCase());
                });
            });
        }
        remove(languageCode) {
            __classPrivateFieldGet(this, _instances, "m", _edit).call(this, (languages) => {
                languages.delete(languageCode.toLowerCase());
            });
        }
        reset() {
            CookieUtils.delete(COOKIE_KEY);
        }
    },
    _languages = new WeakMap(),
    _instances = new WeakSet(),
    _getOrInitializeLanguages = function _getOrInitializeLanguages() {
        if (!__classPrivateFieldGet(this, _languages, "f")) {
            __classPrivateFieldSet(this, _languages, loadLanguages(), "f");
        }
        return __classPrivateFieldGet(this, _languages, "f");
    },
    _edit = function _edit(callback) {
        const languages = __classPrivateFieldGet(this, _instances, "m", _getOrInitializeLanguages).call(this);
        callback(languages);
        CookieUtils.set(COOKIE_KEY, Array.from(languages));
    },
    _a)();
