var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _markFilters;
import UserLibrary from '@root/user/library';
import { LOCALSTORAGE_KEY } from '@root/javascript/library/constants';
import LocalStorageUtils from '@local-storage-utils';
import DomJson from '@utils/dom-json';
import DomMultiJson from '@utils/dom-multi-json';
import Favorites from '../../user/favorites';
import ReleaseSchedulePreferences from '../../user/release-schedule-preferences';
import GlobalPreferences from '../../user/global-preferences';
import ClientFlags from '../../user/client-flags';
import MarkFilterPreferences from '../../user/mark-filter-preferences';
import LinkPreferences from '../../user/link-preferences';
export default class {
    constructor() {
        var _a;
        _markFilters.set(this, {});
        const loggedInMetaElement = document.querySelector('meta[name="logged-in"]');
        this.isLoggedIn = !!((loggedInMetaElement === null || loggedInMetaElement === void 0 ? void 0 : loggedInMetaElement.getAttribute('content')) === '1');
        this.favorites = new Favorites(DomMultiJson('script[name="viewer_favorites"]'));
        this.releaseSchedulePreferences = new ReleaseSchedulePreferences();
        this.library = new UserLibrary(this, (_a = DomJson('library_entries')) !== null && _a !== void 0 ? _a : LocalStorageUtils.get(LOCALSTORAGE_KEY, {}, true));
        this.preferences = new GlobalPreferences();
        this.linkPreferences = new LinkPreferences();
        this.clientFlags = new ClientFlags();
    }
    markFilter(scope = null) {
        var _a;
        var _b;
        (_a = (_b = __classPrivateFieldGet(this, _markFilters, "f"))[scope]) !== null && _a !== void 0 ? _a : (_b[scope] = new MarkFilterPreferences(scope));
        return __classPrivateFieldGet(this, _markFilters, "f")[scope];
    }
}
_markFilters = new WeakMap();
