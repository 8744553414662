var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _UserLibrary_instances, _UserLibrary_rawMarks, _UserLibrary_owner, _UserLibrary_localBus$, _UserLibrary_emit;
import LiveChartSnackbar from '@root/util/livechart-snackbar';
import LocalStorageUtils from '@local-storage-utils';
import graphqlClient, { gql, ERROR_EXTENSIONS } from '@root/javascript/graphql/client';
import * as LibraryConstants from 'javascript/library/constants';
import handleError from '@root/javascript/util/handle-error';
import { LibraryEntryInputModel, } from '@root/user/library-entry-input-model';
import { ApplicationEvent, bus$, publishEvent } from '@utils/broadcast-channel';
import { Subject, share, timer } from 'rxjs';
class UserLibrary {
    constructor(owner, entries) {
        _UserLibrary_instances.add(this);
        _UserLibrary_rawMarks.set(this, void 0);
        _UserLibrary_owner.set(this, void 0);
        _UserLibrary_localBus$.set(this, new Subject());
        this.changes$ = __classPrivateFieldGet(this, _UserLibrary_localBus$, "f").pipe(share({
            resetOnRefCountZero: () => timer(1000),
        }));
        __classPrivateFieldSet(this, _UserLibrary_owner, owner, "f");
        __classPrivateFieldSet(this, _UserLibrary_rawMarks, entries || {}, "f");
        bus$.subscribe((message) => {
            const { data } = message;
            if (data.event === ApplicationEvent.MarkChanged) {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Mark changed message received', message);
                }
                const payload = data.payload;
                const { animeId, attributes: entry } = payload;
                if (entry) {
                    this.store(animeId, entry);
                }
                else {
                    this.remove(animeId);
                }
                __classPrivateFieldGet(this, _UserLibrary_localBus$, "f").next(payload);
            }
        });
    }
    resolveStatusFrom(value) {
        if (typeof value === 'undefined')
            return null;
        return typeof value === 'string' ? value : value.status;
    }
    statusOf(animeId) {
        return this.resolveStatusFrom(this.get(animeId));
    }
    saveLocally() {
        // Don't save authenticated user's marks to localStorage since properties
        // such as notes and dates are too large for localStorage.
        if (!__classPrivateFieldGet(this, _UserLibrary_owner, "f").isLoggedIn) {
            LocalStorageUtils.set(LibraryConstants.LOCALSTORAGE_KEY, __classPrivateFieldGet(this, _UserLibrary_rawMarks, "f"));
        }
    }
    replace(marks) {
        __classPrivateFieldSet(this, _UserLibrary_rawMarks, marks, "f");
    }
    get(animeId) {
        return __classPrivateFieldGet(this, _UserLibrary_rawMarks, "f")[animeId] || {};
    }
    has(animeId) {
        return Object.prototype.hasOwnProperty.call(__classPrivateFieldGet(this, _UserLibrary_rawMarks, "f"), animeId);
    }
    store(animeId, entry) {
        __classPrivateFieldGet(this, _UserLibrary_rawMarks, "f")[animeId] = Object.assign(__classPrivateFieldGet(this, _UserLibrary_rawMarks, "f")[animeId] || {}, entry);
        this.saveLocally();
    }
    remove(animeId) {
        delete __classPrivateFieldGet(this, _UserLibrary_rawMarks, "f")[animeId];
        this.saveLocally();
    }
    setFromApi(animeId, graphEntry) {
        var _a;
        if (!graphEntry) {
            this.remove(animeId);
            __classPrivateFieldGet(this, _UserLibrary_instances, "m", _UserLibrary_emit).call(this, animeId);
            return null;
        }
        const entry = {
            status: (_a = graphEntry.status) === null || _a === void 0 ? void 0 : _a.toLowerCase(),
            episodes_watched: graphEntry.episodesWatched,
            rating: graphEntry.rating,
            rewatches: graphEntry.rewatches,
            started_at: graphEntry.startedAt,
            finished_at: graphEntry.finishedAt,
            notes: graphEntry.notes,
        };
        this.store(animeId, entry);
        __classPrivateFieldGet(this, _UserLibrary_instances, "m", _UserLibrary_emit).call(this, animeId, entry);
        return entry;
    }
    set(animeId, attributes) {
        const { hasOwnProperty } = Object.prototype;
        if (hasOwnProperty.call(attributes, 'status') && !attributes.status) {
            return this.delete(animeId);
        }
        // We do create a new object for the old data to avoid modifying the same object in the cache.
        const oldEntry = { ...this.get(animeId) };
        const newEntry = { ...oldEntry, ...attributes };
        this.store(animeId, newEntry);
        if (!__classPrivateFieldGet(this, _UserLibrary_owner, "f").isLoggedIn) {
            __classPrivateFieldGet(this, _UserLibrary_instances, "m", _UserLibrary_emit).call(this, animeId, newEntry);
            // LiveChartSnackbar.show({
            //   text: 'Sign in to avoid losing your marks',
            //   actionText: 'MORE INFO',
            //   customClass: 'snackbar-info',
            //   onActionClick: () => {
            //     window.location.href = 'https://www.livechart.me/pages/faq#mark-persistence'
            //   }
            // })
            return Promise.resolve(newEntry);
        }
        const normalizedAttributes = {};
        if (hasOwnProperty.call(attributes, 'status')) {
            normalizedAttributes.status = attributes.status.toUpperCase();
        }
        if (hasOwnProperty.call(attributes, 'episodes_watched')) {
            normalizedAttributes.episodesWatched = attributes.episodes_watched;
        }
        if (hasOwnProperty.call(attributes, 'rewatches')) {
            normalizedAttributes.rewatches = attributes.rewatches;
        }
        if (hasOwnProperty.call(attributes, 'notes')) {
            normalizedAttributes.notes = attributes.notes;
        }
        if (hasOwnProperty.call(attributes, 'started_at')) {
            normalizedAttributes.startedAt = attributes.started_at;
        }
        if (hasOwnProperty.call(attributes, 'finished_at')) {
            normalizedAttributes.finishedAt = attributes.finished_at;
        }
        if (hasOwnProperty.call(attributes, 'rating')) {
            if (attributes.rating) {
                normalizedAttributes.rating = +attributes.rating;
            }
            else {
                normalizedAttributes.rating = null;
            }
        }
        return new Promise((resolve, reject) => {
            // If the user is logged in, save the mark to their account.
            graphqlClient.mutate({
                variables: {
                    animeId,
                    attributes: normalizedAttributes,
                },
                mutation: gql `
          mutation UpsertLibraryEntry($animeId: ID!, $attributes: LibraryEntryAttributes!) {
            upsertLibraryEntry(animeId: $animeId, attributes: $attributes) {
              libraryEntry {
                ...libraryEntryFields
              }

              problems {
                ... on Problem {
                  message
                  path
                }
              }
            }
          }
          ${LibraryConstants.LIBRARY_ENTRY_GQL_FRAGMENT}
        `,
            }).then((result) => {
                var _a, _b;
                let errorMessage = null;
                const data = result.data.upsertLibraryEntry;
                const problem = (_a = data.problems) === null || _a === void 0 ? void 0 : _a[0];
                const error = (_b = result.errors) === null || _b === void 0 ? void 0 : _b[0];
                if (error) {
                    errorMessage = error.message || 'An error occurred while updating your library';
                }
                else if (problem) {
                    errorMessage = problem.message;
                }
                if (errorMessage) {
                    LiveChartSnackbar.show({
                        text: errorMessage,
                        customClass: 'snackbar-error',
                    });
                    reject(oldEntry, result.errors);
                    // Undo the mark if the request failed
                    __classPrivateFieldGet(this, _UserLibrary_instances, "m", _UserLibrary_emit).call(this, animeId, oldEntry);
                    return;
                }
                const returnedEntry = data.libraryEntry;
                resolve(this.setFromApi(animeId, returnedEntry));
            }).catch((e) => {
                console.error(e);
                this.store(animeId, oldEntry);
                // Undo the mark if the request failed
                __classPrivateFieldGet(this, _UserLibrary_instances, "m", _UserLibrary_emit).call(this, animeId, oldEntry);
                if (!handleError(e)) {
                    LiveChartSnackbar.show({
                        text: 'An error occurred while updating your library',
                        customClass: 'snackbar-error',
                    });
                }
                reject(e, oldEntry);
            });
        });
    }
    delete(animeId) {
        const oldData = this.get(animeId);
        this.remove(animeId);
        if (!__classPrivateFieldGet(this, _UserLibrary_owner, "f").isLoggedIn) {
            __classPrivateFieldGet(this, _UserLibrary_instances, "m", _UserLibrary_emit).call(this, animeId);
            return Promise.resolve(null);
        }
        return new Promise((resolve, reject) => {
            graphqlClient.mutate({
                variables: {
                    animeId: `${animeId}`,
                },
                mutation: gql `
          mutation DeleteLibraryEntry($animeId: ID!) {
            deleteLibraryEntry(animeId: $animeId) {
              libraryEntry {
                databaseId
              }

              problems {
                ... on Problem {
                  message
                  path
                }
              }
            }
          }
        `,
            }).then((result) => {
                var _a, _b, _c;
                const data = result.data.deleteLibraryEntry;
                const problem = (_a = data.problems) === null || _a === void 0 ? void 0 : _a[0];
                const error = (_b = result.errors) === null || _b === void 0 ? void 0 : _b[0];
                if (error && ((_c = error.extensions) === null || _c === void 0 ? void 0 : _c.code) !== ERROR_EXTENSIONS.OBJECT_NOT_FOUND) {
                    throw new Error(error.message || 'An error occurred while updating your library');
                }
                if (problem) {
                    throw new Error(problem.message);
                }
                LiveChartSnackbar.show({
                    text: 'Your library has been successfully updated',
                    customClass: 'snackbar-success',
                });
                resolve(this.setFromApi(animeId));
            }).catch((e) => {
                console.error(e);
                this.store(animeId, oldData);
                // Undo the mark if the request failed
                __classPrivateFieldGet(this, _UserLibrary_instances, "m", _UserLibrary_emit).call(this, animeId, oldData);
                if (!handleError(e)) {
                    LiveChartSnackbar.show({
                        text: 'An error occurred while updating your library',
                        customClass: 'snackbar-error',
                    });
                }
                reject(e, oldData);
            });
        });
    }
    toggleStatusOf(animeId, status) {
        if (this.statusOf(animeId) === status) {
            this.delete(animeId);
            return null;
        }
        this.set(animeId, { status });
        return status;
    }
    incrementEpisodesWatched(animeId) {
        const initialModel = new LibraryEntryInputModel(this.get(animeId));
        const resultModel = initialModel.withChanges(initialModel.incrementEpisodesWatched(1));
        return this.set(animeId, resultModel.toDelta());
    }
    setEpisodesWatched(animeId, value) {
        const initialModel = new LibraryEntryInputModel(this.get(animeId));
        const resultModel = initialModel.withChanges(initialModel.changeEpisodesWatched(value));
        return this.set(animeId, resultModel.toDelta());
    }
    get all() {
        return __classPrivateFieldGet(this, _UserLibrary_rawMarks, "f");
    }
    canRate(status, episodesWatched, allowUnmarked = null) {
        if (!__classPrivateFieldGet(this, _UserLibrary_owner, "f").isLoggedIn) {
            return false;
        }
        const { LibraryStatus } = LibraryConstants;
        return status === LibraryStatus.Completed
            || status === LibraryStatus.Rewatching
            || (status && episodesWatched && episodesWatched > 0)
            || (allowUnmarked === true && status !== LibraryStatus.Skipping);
    }
}
_UserLibrary_rawMarks = new WeakMap(), _UserLibrary_owner = new WeakMap(), _UserLibrary_localBus$ = new WeakMap(), _UserLibrary_instances = new WeakSet(), _UserLibrary_emit = function _UserLibrary_emit(animeId, entry) {
    const payload = { animeId, attributes: entry };
    if (entry) {
        payload.attributes = entry;
    }
    __classPrivateFieldGet(this, _UserLibrary_localBus$, "f").next(payload);
    publishEvent(ApplicationEvent.MarkChanged, payload);
};
export default UserLibrary;
