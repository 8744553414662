var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _MarkIconController_instances, _MarkIconController_isComparing, _MarkIconController_appliedPrimaryStatus, _MarkIconController_appliedSecondaryStatus, _MarkIconController_viewerStatus_get, _MarkIconController_otherStatus_get, _MarkIconController_primaryStatus_get, _MarkIconController_secondaryStatus_get, _MarkIconController_applyStatus, _MarkIconController_ensureSecondaryPath;
import { Controller } from '@local-stimulus';
import { STATUSES } from '@javascript/library/constants';
const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
const PRIMARY_PATH_TARGET_NAME = 'primaryPath';
const SECONDARY_PATH_TARGET_NAME = 'secondaryPath';
const MARK_ICON_TARGET_DATASET_KEY = 'markIconTarget';
const UNMARKED_ID = 'icon:mark:none';
function getStandaloneSymbolId(status) {
    return `icon:mark:${status}`;
}
function getPrimarySymbolId(status) {
    return `icon:mark:${status}:primary`;
}
function getSecondarySymbolId(status) {
    return `icon:mark:${status}:secondary`;
}
function createSVGElement(qualifiedName) {
    return document.createElementNS(SVG_NAMESPACE, qualifiedName);
}
const secondaryFragment = (() => {
    let instance;
    return () => {
        if (!instance) {
            instance = document.createDocumentFragment();
            const secondaryPath = createSVGElement('use');
            secondaryPath.setAttribute('class', 'secondary-path');
            secondaryPath.dataset[MARK_ICON_TARGET_DATASET_KEY] = SECONDARY_PATH_TARGET_NAME;
            instance.appendChild(secondaryPath);
        }
        return instance.cloneNode(true);
    };
})();
function applyStatus(target, symbolId) {
    target.setAttributeNS(null, 'href', `#${symbolId}`);
}
export default class MarkIconController extends Controller {
    constructor() {
        super(...arguments);
        _MarkIconController_instances.add(this);
        _MarkIconController_isComparing.set(this, false);
        _MarkIconController_appliedPrimaryStatus.set(this, void 0);
        _MarkIconController_appliedSecondaryStatus.set(this, void 0);
    }
    initialize() {
        this.element[this.identifier] = this;
    }
    set viewerStatus(value) {
        this.viewerStatusValue = value || '';
    }
    set otherStatus(value) {
        this.otherStatusValue = value || '';
    }
    viewerStatusValueChanged(value, previousValue) {
        __classPrivateFieldGet(this, _MarkIconController_instances, "m", _MarkIconController_applyStatus).call(this);
    }
    otherStatusValueChanged(value, previousValue) {
        __classPrivateFieldSet(this, _MarkIconController_isComparing, value !== null && STATUSES.has(value), "f");
        __classPrivateFieldGet(this, _MarkIconController_instances, "m", _MarkIconController_applyStatus).call(this);
    }
}
_MarkIconController_isComparing = new WeakMap(), _MarkIconController_appliedPrimaryStatus = new WeakMap(), _MarkIconController_appliedSecondaryStatus = new WeakMap(), _MarkIconController_instances = new WeakSet(), _MarkIconController_viewerStatus_get = function _MarkIconController_viewerStatus_get() {
    return this.viewerStatusValue || null;
}, _MarkIconController_otherStatus_get = function _MarkIconController_otherStatus_get() {
    return this.otherStatusValue || null;
}, _MarkIconController_primaryStatus_get = function _MarkIconController_primaryStatus_get() {
    return (__classPrivateFieldGet(this, _MarkIconController_isComparing, "f") ? __classPrivateFieldGet(this, _MarkIconController_instances, "a", _MarkIconController_otherStatus_get) : __classPrivateFieldGet(this, _MarkIconController_instances, "a", _MarkIconController_viewerStatus_get)) || null;
}, _MarkIconController_secondaryStatus_get = function _MarkIconController_secondaryStatus_get() {
    return (__classPrivateFieldGet(this, _MarkIconController_isComparing, "f") ? __classPrivateFieldGet(this, _MarkIconController_instances, "a", _MarkIconController_viewerStatus_get) : __classPrivateFieldGet(this, _MarkIconController_instances, "a", _MarkIconController_otherStatus_get)) || null;
}, _MarkIconController_applyStatus = function _MarkIconController_applyStatus() {
    if (!this.hasPrimaryPathTarget) {
        return;
    }
    const primaryStatus = __classPrivateFieldGet(this, _MarkIconController_instances, "a", _MarkIconController_primaryStatus_get);
    const secondaryStatus = __classPrivateFieldGet(this, _MarkIconController_instances, "a", _MarkIconController_secondaryStatus_get);
    if (__classPrivateFieldGet(this, _MarkIconController_appliedPrimaryStatus, "f") === primaryStatus
        && __classPrivateFieldGet(this, _MarkIconController_appliedSecondaryStatus, "f") === secondaryStatus) {
        return;
    }
    __classPrivateFieldSet(this, _MarkIconController_appliedPrimaryStatus, primaryStatus, "f");
    __classPrivateFieldSet(this, _MarkIconController_appliedSecondaryStatus, secondaryStatus, "f");
    const showComparing = !!primaryStatus && !!secondaryStatus;
    __classPrivateFieldGet(this, _MarkIconController_instances, "m", _MarkIconController_ensureSecondaryPath).call(this, showComparing);
    let primarySymbolId = UNMARKED_ID;
    const { primaryPathTarget } = this;
    if (showComparing) {
        primarySymbolId = getPrimarySymbolId(primaryStatus);
        applyStatus(this.secondaryPathTarget, getSecondarySymbolId(secondaryStatus));
    }
    else if (primaryStatus !== null && STATUSES.has(primaryStatus)) {
        primarySymbolId = getStandaloneSymbolId(primaryStatus);
    }
    applyStatus(primaryPathTarget, primarySymbolId);
}, _MarkIconController_ensureSecondaryPath = function _MarkIconController_ensureSecondaryPath(presence) {
    if (!presence) {
        if (this.hasSecondaryPathTarget) {
            this.secondaryPathTarget.remove();
        }
        return;
    }
    if (this.hasSecondaryPathTarget) {
        return;
    }
    this.element.appendChild(secondaryFragment());
};
MarkIconController.targets = [PRIMARY_PATH_TARGET_NAME, SECONDARY_PATH_TARGET_NAME];
MarkIconController.values = {
    viewerStatus: String,
    otherStatus: String,
};
