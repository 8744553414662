var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _CookieBackedPreferences_instances, _CookieBackedPreferences_saveInternal;
import CookieUtils from '@utils/cookie-utils';
class CookieBackedPreferences {
    constructor(cookieKey, { defaultValue = {}, cookiePath = CookieUtils.BASE_COOKIE_PATH, } = {}) {
        _CookieBackedPreferences_instances.add(this);
        this.cookieKey = cookieKey;
        this.cookiePath = cookiePath;
        this.defaultValue = defaultValue;
    }
    set(key, value) {
        this.reload();
        const prefs = this.all;
        if (prefs[key] !== value) {
            prefs[key] = value;
            __classPrivateFieldGet(this, _CookieBackedPreferences_instances, "m", _CookieBackedPreferences_saveInternal).call(this, prefs);
            return true;
        }
        return false;
    }
    /**
     * Sets multiple preferences at once. This is more efficient than multiple {@link set} calls
     * due to the fact that each call to {@link set} loads & parses the entire preference cookie
     * and re-encodes it to save.
     *
     * @param {object} values a hash of values to set
     *
     * @returns {number} number of preferences set
     */
    setMultiple(values) {
        this.reload();
        const prefs = this.all;
        let changedCount = 0;
        Object.entries(values).forEach(([key, value]) => {
            if (prefs[key] !== value) {
                prefs[key] = value;
                changedCount += 1;
            }
        });
        if (changedCount > 0) {
            __classPrivateFieldGet(this, _CookieBackedPreferences_instances, "m", _CookieBackedPreferences_saveInternal).call(this, prefs);
        }
        return changedCount;
    }
    get(key, defaultValue) {
        if (key && !this.has(key)) {
            return defaultValue;
        }
        return key ? this.all[key] : this.all;
    }
    getTyped(key, defaultValue) {
        if (!this.has(key)) {
            return defaultValue;
        }
        return this.all[key];
    }
    get all() {
        var _a;
        (_a = this.value) !== null && _a !== void 0 ? _a : (this.value = CookieUtils.getTyped(this.cookieKey, this.defaultValue));
        return this.value;
    }
    isEmpty() {
        const { all } = this;
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in all) {
            if (Object.prototype.hasOwnProperty.call(all, prop)) {
                return false;
            }
        }
        return true;
    }
    has(key) {
        return Object.prototype.hasOwnProperty.call(this.all, key);
    }
    remove(key) {
        this.reload();
        if (this.has(key)) {
            const prefs = this.all;
            delete prefs[key];
            __classPrivateFieldGet(this, _CookieBackedPreferences_instances, "m", _CookieBackedPreferences_saveInternal).call(this, prefs);
            return true;
        }
        return false;
    }
    reload() {
        this.value = null;
    }
}
_CookieBackedPreferences_instances = new WeakSet(), _CookieBackedPreferences_saveInternal = function _CookieBackedPreferences_saveInternal(prefs) {
    CookieUtils.set(this.cookieKey, prefs, {
        path: this.cookiePath,
        expiresInSeconds: CookieUtils.EXPIRES_IN_PERMANENT,
    });
};
export default CookieBackedPreferences;
