import DomJson from '@root/util/dom-json';
import CookieBackedPreferences from './cookie-backed-preferences';
const MarkFilterFromDom = DomJson('mark_filters', {});
const COOKIE_KEY = 'mark_filters';
var FilterKey;
(function (FilterKey) {
    FilterKey["Unmarked"] = "unmarked";
})(FilterKey || (FilterKey = {}));
var MarkFilterScope;
(function (MarkFilterScope) {
    MarkFilterScope["Charts"] = "charts";
    MarkFilterScope["Timetable"] = "timetable";
    MarkFilterScope["Streams"] = "streams";
})(MarkFilterScope || (MarkFilterScope = {}));
function makeCookieKey(scope) {
    if (scope) {
        return `${COOKIE_KEY}__${scope}`;
    }
    return COOKIE_KEY;
}
export default class extends CookieBackedPreferences {
    constructor(scope = null) {
        super(makeCookieKey(scope), { defaultValue: MarkFilterFromDom });
        this.scope = null;
        this.scope = scope;
    }
    isLibraryStatusFiltered(status) {
        return this.get(status || FilterKey.Unmarked) === false;
    }
}
export { MarkFilterScope };
