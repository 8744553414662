var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _LibraryEntryInputModel_errors;
import { LibraryStatus } from '@root/javascript/library/constants';
import { PresentValue, AbsentValue, putOptionalToMap, sameValue, valueOf, valueOfOrNull, } from '@javascript/common/optional';
function n(value) {
    if (value === null || value === undefined) {
        return '';
    }
    return `${value}`;
}
function todayISOString() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T', 2)[0];
}
function coerceNumber(value, defaultValue) {
    let coercedValue = defaultValue;
    if (value)
        coercedValue = +value;
    return coercedValue;
}
var LibraryEntryAttributeName;
(function (LibraryEntryAttributeName) {
    LibraryEntryAttributeName["STATUS"] = "status";
    LibraryEntryAttributeName["EPISODES_WATCHED"] = "episodes_watched";
    LibraryEntryAttributeName["RATING"] = "rating";
    LibraryEntryAttributeName["REWATCHES"] = "rewatches";
    LibraryEntryAttributeName["STARTED_AT"] = "started_at";
    LibraryEntryAttributeName["FINISHED_AT"] = "finished_at";
    LibraryEntryAttributeName["NOTES"] = "notes";
})(LibraryEntryAttributeName || (LibraryEntryAttributeName = {}));
class CharactersRemaining {
    constructor(currentLength, limit) {
        this.currentLength = currentLength;
        this.limit = limit;
    }
    get remaining() {
        return this.limit - this.currentLength;
    }
    get percent() {
        return this.currentLength / this.limit;
    }
}
class ChangedLibraryEntryAttributes {
    constructor(status = AbsentValue, rewatches = AbsentValue, episodesWatched = AbsentValue, rating = AbsentValue, startedAt = AbsentValue, finishedAt = AbsentValue, notes = AbsentValue) {
        this.status = status;
        this.rewatches = rewatches;
        this.episodesWatched = episodesWatched;
        this.rating = rating;
        this.startedAt = startedAt;
        this.finishedAt = finishedAt;
        this.notes = notes;
    }
    copy({ status = this.status, rewatches = this.rewatches, episodesWatched = this.episodesWatched, rating = this.rating, startedAt = this.startedAt, finishedAt = this.finishedAt, notes = this.notes, }) {
        return new ChangedLibraryEntryAttributes(status, rewatches, episodesWatched, rating, startedAt, finishedAt, notes);
    }
    toMap() {
        const result = new Map();
        putOptionalToMap(result, LibraryEntryAttributeName.STATUS, this.status);
        putOptionalToMap(result, LibraryEntryAttributeName.REWATCHES, this.rewatches);
        putOptionalToMap(result, LibraryEntryAttributeName.EPISODES_WATCHED, this.episodesWatched);
        putOptionalToMap(result, LibraryEntryAttributeName.RATING, this.rating);
        putOptionalToMap(result, LibraryEntryAttributeName.STARTED_AT, this.startedAt);
        putOptionalToMap(result, LibraryEntryAttributeName.FINISHED_AT, this.finishedAt);
        putOptionalToMap(result, LibraryEntryAttributeName.NOTES, this.notes);
        return result;
    }
    equals(other) {
        return sameValue(this.status, other.status)
            && sameValue(this.episodesWatched, other.episodesWatched)
            && sameValue(this.rating, other.rating)
            && sameValue(this.rewatches, other.rewatches)
            && sameValue(this.startedAt, other.startedAt)
            && sameValue(this.finishedAt, other.finishedAt)
            && sameValue(this.notes, other.notes);
    }
}
var LibraryEntryError;
(function (LibraryEntryError) {
    LibraryEntryError[LibraryEntryError["ERROR_TOO_LONG"] = 1] = "ERROR_TOO_LONG";
    LibraryEntryError[LibraryEntryError["ERROR_INVALID_VALUE"] = 2] = "ERROR_INVALID_VALUE";
})(LibraryEntryError || (LibraryEntryError = {}));
class LibraryEntryInputModel {
    constructor(cleanLibraryEntry, changes = new ChangedLibraryEntryAttributes(), totalEpisodeCount = null) {
        var _a;
        _LibraryEntryInputModel_errors.set(this, void 0);
        this.notesCharacterLimit = 500;
        this.cleanLibraryEntry = cleanLibraryEntry || null;
        this.changes = changes;
        this.totalEpisodeCount = totalEpisodeCount;
        const episodesWatched = valueOf(changes.episodesWatched, cleanLibraryEntry.episodes_watched) || 0;
        this.status = valueOf(changes.status, cleanLibraryEntry.status) || LibraryStatus.Watching;
        this.episodesWatched = episodesWatched;
        this.rating = valueOf(changes.rating, cleanLibraryEntry.rating);
        this.rewatches = valueOf(changes.rewatches, cleanLibraryEntry.rewatches) || 0;
        this.startedAt = valueOf(changes.startedAt, cleanLibraryEntry.started_at);
        this.finishedAt = valueOf(changes.finishedAt, cleanLibraryEntry.finished_at);
        this.notes = valueOf(changes.notes, cleanLibraryEntry.notes);
        const { status } = this;
        this.minEpisodesWatched = 0;
        const notesCharacters = ((_a = this.notes) === null || _a === void 0 ? void 0 : _a.length) || 0;
        this.notesCharacters = notesCharacters;
        this.notesCharactersRemaining = this.notesCharacterLimit - notesCharacters;
        this.canChangeEpisodesWatched = this.status !== LibraryStatus.Completed;
        this.canIncrementEpisodesWatched = this.canChangeEpisodesWatched && (totalEpisodeCount == null || episodesWatched < totalEpisodeCount);
        this.canDecrementEpisodesWatched = this.canChangeEpisodesWatched && episodesWatched > 0;
        this.canSetStartedAt = (this.episodesWatched > 0
            || status === LibraryStatus.Completed
            || status === LibraryStatus.Rewatching);
        this.canSetFinishedAt = (status === LibraryStatus.Completed
            || status === LibraryStatus.Rewatching);
        this.canChangeRewatches = (status === LibraryStatus.Completed
            || status === LibraryStatus.Rewatching);
        this.canRate = window.Viewer.library.canRate(status, episodesWatched);
        this.canDelete = this.cleanLibraryEntry != null;
    }
    get canSave() {
        return this.status && this.isDirty && this.errors.size === 0;
    }
    get isPersisted() {
        return !!this.cleanLibraryEntry.status;
    }
    get errors() {
        if (__classPrivateFieldGet(this, _LibraryEntryInputModel_errors, "f"))
            return __classPrivateFieldGet(this, _LibraryEntryInputModel_errors, "f");
        const result = new Map();
        if (this.startedAt != null && this.finishedAt != null && this.startedAt > this.finishedAt) {
            result.set(LibraryEntryAttributeName.FINISHED_AT, LibraryEntryError.ERROR_INVALID_VALUE);
        }
        if (this.notesCharactersRemaining < 0) {
            result.set(LibraryEntryAttributeName.NOTES, LibraryEntryError.ERROR_TOO_LONG);
        }
        __classPrivateFieldSet(this, _LibraryEntryInputModel_errors, result, "f");
        return result;
    }
    get isDirty() {
        const { cleanLibraryEntry } = this;
        if (cleanLibraryEntry === null)
            return true;
        if (this.status !== cleanLibraryEntry.status)
            return true;
        if (this.episodesWatched !== cleanLibraryEntry.episodes_watched)
            return true;
        if (this.rating !== cleanLibraryEntry.rating)
            return true;
        if (this.rewatches !== cleanLibraryEntry.rewatches)
            return true;
        if (this.startedAt !== cleanLibraryEntry.started_at)
            return true;
        if (this.finishedAt !== cleanLibraryEntry.finished_at)
            return true;
        if (this.notes !== cleanLibraryEntry.notes)
            return true;
        return false;
    }
    changeStatus(newStatus) {
        const currentChanges = this.changes;
        if (newStatus === this.status) {
            return currentChanges;
        }
        const { cleanLibraryEntry } = this;
        const currentStatus = cleanLibraryEntry === null || cleanLibraryEntry === void 0 ? void 0 : cleanLibraryEntry.status;
        const cleanEpisodesWatched = cleanLibraryEntry === null || cleanLibraryEntry === void 0 ? void 0 : cleanLibraryEntry.episodes_watched;
        let resultEpisodesWatched = currentChanges.episodesWatched;
        let resultRewatches = currentChanges.rewatches;
        let resultStartedAt = currentChanges.startedAt;
        let resultFinishedAt = currentChanges.finishedAt;
        if (newStatus === LibraryStatus.Rewatching && currentChanges.episodesWatched === AbsentValue) {
            resultEpisodesWatched = new PresentValue(1);
        }
        if (newStatus === LibraryStatus.Watching
            && currentChanges.startedAt === AbsentValue
            && currentStatus // Only when there's an existing mark
            && currentStatus !== LibraryStatus.Rewatching // The existing mark wasn't Rewatching
            && cleanEpisodesWatched === 0 // The user hadn't watched any episodes prior
            && !this.startedAt) {
            resultStartedAt = new PresentValue(todayISOString());
        }
        if (newStatus === LibraryStatus.Completed
            && currentChanges.finishedAt === AbsentValue
            && currentStatus // Only when there's an existing status
            && currentStatus !== LibraryStatus.Rewatching // The existing status wasn't Rewatching
            && cleanEpisodesWatched
            && cleanEpisodesWatched > 0 // The user had watched episodes prior
            && !this.finishedAt) {
            resultFinishedAt = new PresentValue(todayISOString());
        }
        if (newStatus === LibraryStatus.Completed
            && currentStatus === LibraryStatus.Rewatching
            && currentChanges.rewatches === AbsentValue) {
            resultRewatches = new PresentValue(~~(cleanLibraryEntry === null || cleanLibraryEntry === void 0 ? void 0 : cleanLibraryEntry.rewatches) + 1);
        }
        return currentChanges.copy({
            status: new PresentValue(newStatus),
            rewatches: resultRewatches,
            episodesWatched: resultEpisodesWatched,
            startedAt: resultStartedAt,
            finishedAt: resultFinishedAt,
        });
    }
    incrementEpisodesWatched(amount) {
        return this.changeEpisodesWatched(this.episodesWatched + amount);
    }
    changeEpisodesWatched(newEpisodesWatched) {
        const currentChanges = this.changes;
        const { totalEpisodeCount, cleanLibraryEntry } = this;
        const currentStatus = this.status;
        let resultEpisodesWatched = new PresentValue(null);
        let resultStatus = currentChanges.status;
        let resultRewatches = currentChanges.rewatches;
        let resultStartedAt = currentChanges.startedAt;
        if (newEpisodesWatched !== null) {
            if (totalEpisodeCount !== null) {
                resultEpisodesWatched = new PresentValue(Math.min(newEpisodesWatched, totalEpisodeCount));
            }
            else {
                resultEpisodesWatched = new PresentValue(Math.max(newEpisodesWatched, 0));
            }
            if (totalEpisodeCount !== null && totalEpisodeCount <= newEpisodesWatched) {
                resultStatus = new PresentValue(LibraryStatus.Completed);
            }
            else if (newEpisodesWatched > 0) {
                if (newEpisodesWatched > this.episodesWatched
                    && currentStatus !== LibraryStatus.Dropped
                    && currentStatus !== LibraryStatus.Rewatching
                    && currentChanges.status === AbsentValue) {
                    resultStatus = new PresentValue(LibraryStatus.Watching);
                }
                else if (currentStatus === LibraryStatus.Skipping) {
                    resultStatus = new PresentValue(LibraryStatus.Dropped);
                }
            }
            if (newEpisodesWatched > 0
                && currentChanges.startedAt === AbsentValue
                && (cleanLibraryEntry === null || cleanLibraryEntry === void 0 ? void 0 : cleanLibraryEntry.episodes_watched) === 0
                && currentStatus !== LibraryStatus.Dropped
                && currentStatus !== LibraryStatus.Rewatching
                && !this.startedAt) {
                resultStartedAt = new PresentValue(todayISOString());
            }
        }
        if (currentStatus === LibraryStatus.Rewatching
            && valueOfOrNull(resultStatus) === LibraryStatus.Completed
            && currentChanges.rewatches === AbsentValue) {
            resultRewatches = new PresentValue(this.rewatches + 1);
        }
        return currentChanges.copy({
            status: resultStatus,
            rewatches: resultRewatches,
            episodesWatched: resultEpisodesWatched,
            startedAt: resultStartedAt,
        });
    }
    toAttributes() {
        return {
            status: this.status,
            episodes_watched: this.episodesWatched,
            rating: this.rating,
            rewatches: this.rewatches,
            started_at: this.startedAt,
            finished_at: this.finishedAt,
            notes: this.notes,
        };
    }
    toDelta() {
        const result = {};
        const { cleanLibraryEntry } = this;
        const { status, episodesWatched, rating, rewatches, startedAt, finishedAt, notes, } = this;
        if (status !== cleanLibraryEntry.status)
            result.status = status;
        if (episodesWatched !== cleanLibraryEntry.episodes_watched) {
            result.episodes_watched = episodesWatched;
        }
        if (rating !== cleanLibraryEntry.rating)
            result.rating = rating;
        if (rewatches !== cleanLibraryEntry.rewatches)
            result.rewatches = rewatches;
        if (startedAt !== cleanLibraryEntry.started_at)
            result.started_at = startedAt;
        if (finishedAt !== cleanLibraryEntry.finished_at)
            result.finished_at = finishedAt;
        if (notes !== cleanLibraryEntry.notes)
            result.notes = notes;
        return result;
    }
    withChanges(newChanges) {
        const existingChanges = this.changes;
        if (newChanges === existingChanges) {
            return this;
        }
        return new LibraryEntryInputModel(this.cleanLibraryEntry, newChanges, this.totalEpisodeCount);
    }
}
_LibraryEntryInputModel_errors = new WeakMap();
export { LibraryEntryAttributeName, LibraryEntryError, LibraryEntryInputModel, ChangedLibraryEntryAttributes, CharactersRemaining, };
