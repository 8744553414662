var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _instances, _isInitialized$, _cleanEntry$, _totalEpisodes$, _dirtyAttributes$, _beforeUnloadEvent, _subscriptions, _update, _persist;
import { PresentValue, } from '@javascript/common/optional';
import BrowserStorageUtils from '@browser-storage-utils';
import { Controller } from '@local-stimulus';
import { BehaviorSubject, combineLatest, first, map, shareReplay, Subscription, } from 'rxjs';
import { LibraryEntryInputModel, ChangedLibraryEntryAttributes, LibraryEntryAttributeName, CharactersRemaining, } from '@root/user/library-entry-input-model';
import '@styles/library-editor';
const LIBRARY_DATALOSS_ACKNOWLEDGED = '__LIBRARY_DATALOSS_ACKNOWLEDGED__';
const LocalStorageUtils = BrowserStorageUtils.localStorage;
const SessionStorageUtils = BrowserStorageUtils.sessionStorage;
// Normalize function
function n(value) {
    if (value === null || value === undefined) {
        return '';
    }
    return `${value}`;
}
function coerceNumber(value, defaultValue) {
    let coercedValue = defaultValue;
    if (value)
        coercedValue = +value;
    return coercedValue;
}
function coerceNumberNullable(value, defaultValue) {
    let coercedValue = defaultValue;
    if (value)
        coercedValue = +value;
    return coercedValue;
}
const CLOSE_AFTER_SAVING = 'close-after-saving';
export default class extends Controller {
    constructor() {
        super(...arguments);
        _instances.add(this);
        _isInitialized$.set(this, new BehaviorSubject(false));
        _cleanEntry$.set(this, new BehaviorSubject(null));
        _totalEpisodes$.set(this, new BehaviorSubject(Number.MAX_SAFE_INTEGER));
        this.isProcessing$ = new BehaviorSubject(false);
        _dirtyAttributes$.set(this, new BehaviorSubject(new ChangedLibraryEntryAttributes()));
        _beforeUnloadEvent.set(this, (e) => {
            this.withModel((model) => {
                if (model.canSave) {
                    e.returnValue = 'Changes you made may not be saved.';
                }
            });
        });
        this.model$ = combineLatest([
            __classPrivateFieldGet(this, _cleanEntry$, "f"),
            __classPrivateFieldGet(this, _dirtyAttributes$, "f"),
            __classPrivateFieldGet(this, _totalEpisodes$, "f"),
        ]).pipe(map(([cleanEntry, dirtyAttributes, totalEpisodes,]) => new LibraryEntryInputModel(cleanEntry, dirtyAttributes, totalEpisodes)), shareReplay({ bufferSize: 1, refCount: true }));
        this.notesCharactersRemaining$ = this.model$.pipe(map((model) => new CharactersRemaining(model.notesCharacters, model.notesCharacterLimit)), shareReplay({ bufferSize: 1, refCount: true }));
        this.canChange$ = combineLatest([
            __classPrivateFieldGet(this, _isInitialized$, "f"),
            this.isProcessing$,
        ]).pipe(map(([isInitialized, isProcessing,]) => isInitialized && !isProcessing), shareReplay({ bufferSize: 1, refCount: true }));
        this.hasUnsavedChanges$ = this.model$.pipe(map((model) => model.isDirty));
        this.canSave$ = combineLatest([
            this.canChange$,
            this.model$,
        ]).pipe(map(([canChange, model,]) => canChange && model.canSave));
        this.canDelete$ = combineLatest([
            this.model$,
            this.canChange$,
        ]).pipe(map(([model, canChange,]) => canChange && model.isPersisted));
        this.canSetStatus$ = this.canChange$;
        this.canSetEpisodesWatched$ = combineLatest([
            this.model$,
            this.canChange$,
        ]).pipe(map(([model, canChange,]) => canChange && model.canChangeEpisodesWatched));
        this.episodesWatchedConstraints$ = this.model$.pipe(map((model) => [model.minEpisodesWatched, model.totalEpisodeCount]));
        this.canSetRewatches$ = combineLatest([
            this.model$,
            this.canChange$,
        ]).pipe(map(([model, canChange,]) => canChange && model.canChangeRewatches));
        this.canRate$ = combineLatest([
            this.model$,
            this.canChange$,
        ]).pipe(map(([model, canChange]) => canChange && model.canRate));
        this.canSetNotes$ = this.canChange$;
        this.canSetStartedAt$ = combineLatest([
            this.model$,
            this.canChange$,
        ]).pipe(map(([model, canChange]) => canChange && model.canSetStartedAt));
        this.canSetFinishedAt$ = combineLatest([
            this.model$,
            this.canChange$,
        ]).pipe(map(([model, canChange]) => canChange && model.canSetFinishedAt));
        _subscriptions.set(this, new Subscription());
    }
    connect() {
        const { animeId } = this;
        const { Viewer } = window;
        if (animeId) {
            this.setupUI();
            __classPrivateFieldGet(this, _cleanEntry$, "f").next(Viewer.library.get(animeId));
        }
        if (!Viewer.isLoggedIn && !SessionStorageUtils.has(LIBRARY_DATALOSS_ACKNOWLEDGED)) {
            this.showDatalossWarning();
        }
        window.addEventListener('beforeunload', __classPrivateFieldGet(this, _beforeUnloadEvent, "f"));
    }
    disconnect() {
        __classPrivateFieldGet(this, _subscriptions, "f").unsubscribe();
        window.removeEventListener('beforeunload', __classPrivateFieldGet(this, _beforeUnloadEvent, "f"));
    }
    notifyInitialized() {
        if (!__classPrivateFieldGet(this, _isInitialized$, "f").value) {
            __classPrivateFieldGet(this, _isInitialized$, "f").next(true);
        }
    }
    addSubscription(logic) {
        __classPrivateFieldGet(this, _subscriptions, "f").add(logic);
    }
    get animeId() {
        return this.data.get('animeId');
    }
    get animeTitle() {
        return this.data.get('animeTitle');
    }
    acknowledgeDatalossForSession() {
        SessionStorageUtils.set(LIBRARY_DATALOSS_ACKNOWLEDGED, 1);
    }
    closeOrWarn(e) {
        if (!e || !this.boxTarget.contains(e.target)) {
            this.hasUnsavedChanges$.pipe(first()).subscribe((changed) => {
                if (!changed) {
                    this.close();
                }
                else {
                    this.showUnsavedChangesWarning();
                }
            });
        }
    }
    delete(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        __classPrivateFieldGet(this, _instances, "m", _persist).call(this, () => window.Viewer.library.delete(this.animeId));
    }
    commitChanges(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        __classPrivateFieldGet(this, _instances, "m", _persist).call(this, (model) => window.Viewer.library.set(this.animeId, model.toAttributes()));
    }
    incrementEpisodesWatched(e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        __classPrivateFieldGet(this, _instances, "m", _update).call(this, (model) => {
            if (!model.canChangeEpisodesWatched) {
                return model.changes;
            }
            return model.changeEpisodesWatched((model.episodesWatched || 0) + 1);
        });
    }
    updateInputs(e) {
        if (!__classPrivateFieldGet(this, _isInitialized$, "f").value || !e || !e.target) {
            return;
        }
        const target = e.target;
        if (target) {
            const { name, value } = target;
            if (name === LibraryEntryAttributeName.STATUS) {
                this.setStatus(value);
            }
            else if (name === LibraryEntryAttributeName.EPISODES_WATCHED) {
                this.setEpisodesWatched(coerceNumber(value, 0));
            }
            else if (name === LibraryEntryAttributeName.RATING) {
                this.setRating(coerceNumberNullable(value, null));
            }
            else if (name === LibraryEntryAttributeName.REWATCHES) {
                this.setRewatches(coerceNumber(value, 0));
            }
            else if (name === LibraryEntryAttributeName.STARTED_AT) {
                this.setStartedAt(value || null);
            }
            else if (name === LibraryEntryAttributeName.FINISHED_AT) {
                this.setFinishedAt(value || null);
            }
            else if (name === LibraryEntryAttributeName.NOTES) {
                this.setNotes(value);
            }
        }
    }
    withModel(next) {
        this.model$.pipe(first()).subscribe(next);
    }
    setStatus(value) {
        __classPrivateFieldGet(this, _instances, "m", _update).call(this, (model) => {
            if (!value) {
                return model.changes;
            }
            return model.changeStatus(value);
        });
    }
    setEpisodesWatched(value) {
        __classPrivateFieldGet(this, _instances, "m", _update).call(this, (model) => {
            if (!model.canChangeEpisodesWatched) {
                return model.changes;
            }
            return model.changeEpisodesWatched(value);
        });
    }
    setStartedAt(value) {
        // TODO: Should this be guarded?
        __classPrivateFieldGet(this, _instances, "m", _update).call(this, (model) => model.changes.copy({ startedAt: new PresentValue(value) }));
    }
    setFinishedAt(value) {
        // TODO: Should this be guarded?
        __classPrivateFieldGet(this, _instances, "m", _update).call(this, (model) => model.changes.copy({ finishedAt: new PresentValue(value) }));
    }
    setRating(value) {
        // TODO: Should this be guarded?
        __classPrivateFieldGet(this, _instances, "m", _update).call(this, (model) => model.changes.copy({ rating: new PresentValue(value) }));
    }
    setRewatches(value) {
        const cappedValue = Math.max(0, Math.min(value, 999));
        __classPrivateFieldGet(this, _instances, "m", _update).call(this, (model) => model.changes.copy({ rewatches: new PresentValue(cappedValue) }));
    }
    setNotes(value) {
        __classPrivateFieldGet(this, _instances, "m", _update).call(this, (model) => model.changes.copy({ notes: new PresentValue(value) }));
    }
    toggleCloseAfterSaving() {
        this.closeAfterSaving = !this.closeAfterSaving;
    }
    showMessage(message) {
        this.messageBoxTarget.replaceChildren(message.cloneNode(true));
    }
    clearMessages() {
        this.messageBoxTarget.replaceChildren();
    }
    set closeAfterSaving(value) {
        this.data.set(CLOSE_AFTER_SAVING, value);
        LocalStorageUtils.set(this.closeAfterSavingKey, value);
    }
    get closeAfterSaving() {
        if (this.data.has(CLOSE_AFTER_SAVING)) {
            return JSON.parse(this.data.get(CLOSE_AFTER_SAVING));
        }
        return LocalStorageUtils.getBoolean(this.closeAfterSavingKey, true);
    }
    get closeAfterSavingKey() {
        return `${this.identifier}:${CLOSE_AFTER_SAVING}`;
    }
}
_isInitialized$ = new WeakMap(), _cleanEntry$ = new WeakMap(), _totalEpisodes$ = new WeakMap(), _dirtyAttributes$ = new WeakMap(), _beforeUnloadEvent = new WeakMap(), _subscriptions = new WeakMap(), _instances = new WeakSet(), _update = function _update(callback) {
    this.withModel((model) => {
        const newChanges = callback(model);
        if (!model.changes.equals(newChanges)) {
            __classPrivateFieldGet(this, _dirtyAttributes$, "f").next(newChanges);
        }
    });
}, _persist = function _persist(callback) {
    this.clearMessages();
    this.isProcessing$.next(true);
    this.withModel((model) => {
        callback(model).then((entry) => {
            if (this.closeAfterSaving) {
                this.close();
            }
            else {
                __classPrivateFieldGet(this, _dirtyAttributes$, "f").next(new ChangedLibraryEntryAttributes());
                this.isProcessing$.next(false);
                __classPrivateFieldGet(this, _cleanEntry$, "f").next(entry.status ? entry : null);
            }
        }).catch(() => {
            this.isProcessing$.next(false);
        });
    });
};
export { n, LIBRARY_DATALOSS_ACKNOWLEDGED, LibraryEntryAttributeName };
