var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _EventsChannel_eventName;
import { Subject, filter, share, timer, } from 'rxjs';
var ApplicationEvent;
(function (ApplicationEvent) {
    ApplicationEvent["ThemeChanged"] = "themeChanged";
    ApplicationEvent["MarkChanged"] = "markChanged";
    ApplicationEvent["FavoriteChanged"] = "favoriteChanged";
    ApplicationEvent["ReleaseSchedulePreferenceChanged"] = "releaseSchedulePreferenceChanged";
    ApplicationEvent["NotificationStatusChanged"] = "notification-status-changed";
    ApplicationEvent["NotificationStatsRefreshed"] = "notificationStatsRefreshed";
})(ApplicationEvent || (ApplicationEvent = {}));
const internalEvents$ = new Subject();
const events$ = internalEvents$.pipe(share({
    resetOnRefCountZero: () => timer(1000),
}));
const internalLegacy$ = new Subject();
const bus$ = internalLegacy$.pipe(share({
    resetOnRefCountZero: () => timer(1000),
}));
const channel = typeof BroadcastChannel !== 'undefined' ? new BroadcastChannel('application') : undefined;
export default channel;
channel === null || channel === void 0 ? void 0 : channel.addEventListener('message', (message) => {
    internalLegacy$.next(message);
    internalEvents$.next({
        local: false,
        eventName: message.data.event,
        payload: message.data.payload,
        remoteMessage: message,
    });
});
function publishEvent(eventName, payload) {
    channel === null || channel === void 0 ? void 0 : channel.postMessage({
        event: eventName,
        payload,
    });
}
class EventsChannel {
    constructor(eventName) {
        _EventsChannel_eventName.set(this, void 0);
        __classPrivateFieldSet(this, _EventsChannel_eventName, eventName, "f");
    }
    publish(payload) {
        internalEvents$.next({
            local: true,
            eventName: __classPrivateFieldGet(this, _EventsChannel_eventName, "f"),
            payload,
        });
        publishEvent(__classPrivateFieldGet(this, _EventsChannel_eventName, "f"), payload);
    }
    toObservable() {
        return events$.pipe(filter((value) => value.eventName === __classPrivateFieldGet(this, _EventsChannel_eventName, "f")));
    }
}
_EventsChannel_eventName = new WeakMap();
export { ApplicationEvent, publishEvent, bus$, events$, EventsChannel, };
