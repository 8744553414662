import LocalStorageUtils from '@local-storage-utils';
var ClientFlagKeys;
(function (ClientFlagKeys) {
    ClientFlagKeys["AutoWebp"] = "auto_webp";
})(ClientFlagKeys || (ClientFlagKeys = {}));
export default class {
    get autoWebp() {
        return LocalStorageUtils.getBoolean(ClientFlagKeys.AutoWebp, true);
    }
    set autoWebp(value) {
        if (value === null) {
            LocalStorageUtils.remove(ClientFlagKeys.AutoWebp);
        }
        else {
            LocalStorageUtils.set(ClientFlagKeys.AutoWebp, value);
        }
    }
}
export { ClientFlagKeys, };
