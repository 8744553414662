// Auto generated
export default {
    'en-au': [
        'en',
    ],
    'es-mx': [
        'es-419',
        'es',
    ],
    he: [
        'iw',
    ],
    'zh-hk': [
        'zh-hant',
        'zh-hans',
        'zh',
    ],
    'zh-tw': [
        'zh-hant',
        'zh-hans',
        'zh',
    ],
    'zh-cn': [
        'zh-hant',
        'zh-hans',
        'zh',
    ],
    'zh-hant': [
        'zh-hans',
        'zh',
    ],
    'en-us': [
        'en',
    ],
    'es-es': [
        'es',
    ],
    'es-us': [
        'es-419',
        'es',
    ],
    'zh-hans': [
        'zh',
    ],
    'fr-ca': [
        'fr',
    ],
    'de-de': [
        'de',
    ],
    'en-ca': [
        'en',
    ],
    'es-co': [
        'es-419',
        'es',
    ],
    'fr-fr': [
        'fr',
    ],
    iw: [
        'he',
    ],
    'en-gb': [
        'en',
    ],
    'pt-pt': [
        'pt',
    ],
    'pt-br': [
        'pt',
    ],
    'es-419': [
        'es',
    ],
};
