var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _instances, _map, _changes$, _handleChange, _mapSchedule;
import graphqlClient, { gql } from '@root/javascript/graphql/client';
import broadcastChannel, { ApplicationEvent } from '@utils/broadcast-channel';
import { Subject, share, timer } from 'rxjs';
var ReleaseSchedulePreferenceMutation;
(function (ReleaseSchedulePreferenceMutation) {
    ReleaseSchedulePreferenceMutation["AddPin"] = "pinReleaseSchedule";
    ReleaseSchedulePreferenceMutation["RemovePin"] = "unpinReleaseSchedule";
    ReleaseSchedulePreferenceMutation["UpdateFollowership"] = "updateReleaseScheduleFollowership";
})(ReleaseSchedulePreferenceMutation || (ReleaseSchedulePreferenceMutation = {}));
const FRAGMENT_PIN_MUTATION_RESULT = gql `
  fragment releaseSchedulePinMutationResultFragment on ReleaseSchedulePinMutationResult {
    removedPin {
      ...releaseScheduleFields
    }

    pinnedAfter {
      ...releaseScheduleFields
    }
  }
`;
const FRAGMENT_RELEASE_SCHEDULE = gql `
  fragment releaseScheduleFields on AnimeReleaseSchedule {
    databaseId
    animeDatabaseId

    pinned
    pinnedByViewer
    viewerNotificationsPreference
    bestMatchForViewer
    userPinCount
  }
`;
export default class {
    constructor( /* map: StringHashObject<boolean> */) {
        // this.#map = map;
        _instances.add(this);
        _map.set(this, void 0);
        _changes$.set(this, new Subject());
        this.changes$ = __classPrivateFieldGet(this, _changes$, "f").pipe(share({
            resetOnRefCountZero: () => timer(1000),
        }));
        broadcastChannel === null || broadcastChannel === void 0 ? void 0 : broadcastChannel.addEventListener('message', (message) => {
            const { data } = message;
            if (data.event === ApplicationEvent.ReleaseSchedulePreferenceChanged) {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Release schedule pins changed message received', message);
                }
                const payload = data.payload;
                __classPrivateFieldGet(this, _changes$, "f").next(payload);
            }
        });
    }
    addPin(animeId, releaseScheduleId) {
        return __classPrivateFieldGet(this, _instances, "m", _handleChange).call(this, ReleaseSchedulePreferenceMutation.AddPin, graphqlClient.mutate({
            variables: {
                animeId,
                releaseScheduleId,
            },
            mutation: gql `
        mutation pinReleaseSchedule($animeId: ID!, $releaseScheduleId: ID!) {
          pinReleaseSchedule(
            animeId: $animeId,
            releaseScheduleId: $releaseScheduleId
          ) {
            ...releaseSchedulePinMutationResultFragment

            problems {
              ... on Problem {
                message
                path
              }
            }
          }
        }
        ${FRAGMENT_RELEASE_SCHEDULE}
        ${FRAGMENT_PIN_MUTATION_RESULT}
      `,
        }));
    }
    removePin(animeId, releaseScheduleId) {
        return __classPrivateFieldGet(this, _instances, "m", _handleChange).call(this, ReleaseSchedulePreferenceMutation.RemovePin, graphqlClient.mutate({
            variables: {
                animeId,
                releaseScheduleId,
            },
            mutation: gql `
        mutation unpinReleaseSchedule($animeId: ID!, $releaseScheduleId: ID!) {
          unpinReleaseSchedule(animeId: $animeId, releaseScheduleId: $releaseScheduleId) {
            ...releaseSchedulePinMutationResultFragment

            problems {
              ... on Problem {
                message
                path
              }
            }
          }
        }
        ${FRAGMENT_RELEASE_SCHEDULE}
        ${FRAGMENT_PIN_MUTATION_RESULT}
      `,
        }));
    }
    updateFollowership(animeId, releaseScheduleId, viewerNotificationsPreference) {
        return __classPrivateFieldGet(this, _instances, "m", _handleChange).call(this, ReleaseSchedulePreferenceMutation.UpdateFollowership, graphqlClient.mutate({
            variables: {
                animeId,
                releaseScheduleId,
                viewerNotificationsPreference,
            },
            mutation: gql `
          mutation updateReleaseScheduleFollowership($animeId: ID!, $releaseScheduleId: ID!, $viewerNotificationsPreference: Boolean) {
            updateReleaseScheduleFollowership(
              animeId: $animeId,
              releaseScheduleId: $releaseScheduleId,
              notificationsPreference: $viewerNotificationsPreference
            ) {
              releaseSchedule {
                ...releaseScheduleFields
              }

              problems {
                ... on Problem {
                  message
                  path
                }
              }
            }
          }
          ${FRAGMENT_RELEASE_SCHEDULE}
        `,
        }));
    }
}
_map = new WeakMap(), _changes$ = new WeakMap(), _instances = new WeakSet(), _handleChange = function _handleChange(field, graphqlResponse) {
    return new Promise((resolve, reject) => {
        // If the user is logged in, save the mark to their account.
        graphqlResponse.then((rawResult) => {
            const data = rawResult.data[field];
            if (data.problems.length > 0) {
                console.log(data.problems);
                throw new Error(data.problems[0].message);
            }
            const result = [];
            if (field === ReleaseSchedulePreferenceMutation.UpdateFollowership) {
                result.push(__classPrivateFieldGet(this, _instances, "m", _mapSchedule).call(this, data.releaseSchedule));
            }
            else {
                const removedPin = data === null || data === void 0 ? void 0 : data.removedPin;
                const pinnedAfter = data === null || data === void 0 ? void 0 : data.pinnedAfter;
                if (removedPin) {
                    result.push(__classPrivateFieldGet(this, _instances, "m", _mapSchedule).call(this, removedPin));
                }
                if (pinnedAfter) {
                    result.push(__classPrivateFieldGet(this, _instances, "m", _mapSchedule).call(this, pinnedAfter));
                }
            }
            result.forEach((schedule) => {
                __classPrivateFieldGet(this, _changes$, "f").next(schedule);
                broadcastChannel === null || broadcastChannel === void 0 ? void 0 : broadcastChannel.postMessage({
                    event: ApplicationEvent.ReleaseSchedulePreferenceChanged,
                    payload: schedule,
                });
            });
            resolve(result);
            return result;
        }).catch((e) => {
            console.error('Failed to update release schedule preference', e);
            reject(e);
        });
    });
}, _mapSchedule = function _mapSchedule(releaseSchedule) {
    const result = {
        animeId: releaseSchedule.animeDatabaseId,
        releaseScheduleId: releaseSchedule.databaseId,
        pinned: releaseSchedule.pinned,
        pinnedByViewer: releaseSchedule.pinnedByViewer,
        bestMatchForViewer: releaseSchedule.bestMatchForViewer,
        viewerNotificationsPreference: releaseSchedule.viewerNotificationsPreference,
        userPinCount: releaseSchedule.userPinCount,
    };
    return result;
};
