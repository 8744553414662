class PresentValue {
    constructor(value) {
        this.value = value;
    }
}
const AbsentValue = new class {
}();
function putOptionalToMap(dest, key, value) {
    if (value instanceof PresentValue) {
        dest.set(key, value.value);
    }
}
function sameValue(optionalA, optionalB) {
    if (optionalA === AbsentValue && optionalB === AbsentValue)
        return true;
    if (optionalA === AbsentValue || optionalB === AbsentValue)
        return false;
    if (optionalA instanceof PresentValue && optionalB instanceof PresentValue) {
        return optionalA.value === optionalB.value;
    }
    return false;
}
function valueOf(optional, elseValue) {
    return optional instanceof PresentValue ? optional.value : elseValue;
}
function valueOfOrNull(optional) {
    return valueOf(optional, null);
}
export { PresentValue, AbsentValue, putOptionalToMap, sameValue, valueOf, valueOfOrNull, };
