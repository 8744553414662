var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _instances, _map, _changes$, _set, _change;
import graphqlClient, { gql } from '@root/javascript/graphql/client';
import { ApplicationEvent, publishEvent, bus$ } from '@utils/broadcast-channel';
import { Subject, share, timer } from 'rxjs';
var FavoriteMutation;
(function (FavoriteMutation) {
    FavoriteMutation["Favorite"] = "favorite";
    FavoriteMutation["Unfavorite"] = "unfavorite";
})(FavoriteMutation || (FavoriteMutation = {}));
export default class {
    constructor(map) {
        _instances.add(this);
        _map.set(this, void 0);
        _changes$.set(this, new Subject());
        this.changes$ = __classPrivateFieldGet(this, _changes$, "f").pipe(share({
            resetOnRefCountZero: () => timer(1000),
        }));
        __classPrivateFieldSet(this, _map, map, "f");
        bus$.subscribe((message) => {
            const { data } = message;
            if (data.event === ApplicationEvent.FavoriteChanged) {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Favorites changed message received', message);
                }
                const payload = data.payload;
                __classPrivateFieldGet(this, _instances, "m", _set).call(this, payload.id, payload.viewerHasFavorited);
                __classPrivateFieldGet(this, _changes$, "f").next(payload);
            }
        });
    }
    hasFavorited(id) {
        return __classPrivateFieldGet(this, _map, "f")[id] === true;
    }
    toggle(id) {
        if (this.hasFavorited(id)) {
            return this.unfavorite(id);
        }
        return this.favorite(id);
    }
    favorite(id) {
        return __classPrivateFieldGet(this, _instances, "m", _change).call(this, id, FavoriteMutation.Favorite);
    }
    unfavorite(id) {
        return __classPrivateFieldGet(this, _instances, "m", _change).call(this, id, FavoriteMutation.Unfavorite);
    }
}
_map = new WeakMap(), _changes$ = new WeakMap(), _instances = new WeakSet(), _set = function _set(id, value) {
    __classPrivateFieldGet(this, _map, "f")[id] = value;
}, _change = function _change(id, field) {
    return new Promise((resolve, reject) => {
        // If the user is logged in, save the mark to their account.
        graphqlClient.mutate({
            variables: {
                id,
            },
            mutation: gql `
          mutation ${field}($id: ID!) {
            ${field}(favoritableId: $id) {
              favoritable {
                favoriteCount
                viewerHasFavorited
              }
            }
          }
        `,
        }).then((rawResult) => {
            const data = rawResult.data[field];
            const { favoritable } = data;
            const result = {
                id,
                favoriteCount: favoritable.favoriteCount,
                viewerHasFavorited: favoritable.viewerHasFavorited,
            };
            __classPrivateFieldGet(this, _changes$, "f").next(result);
            publishEvent(ApplicationEvent.FavoriteChanged, result);
            __classPrivateFieldGet(this, _instances, "m", _set).call(this, id, favoritable.viewerHasFavorited);
            resolve(result);
            return result;
        }).catch((e) => {
            console.error('Failed to persist favorite', e);
        });
    });
};
