var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _cache, __markFilters, _a;
import MarkFilters from '@root/user/mark-filters';
const UNSET_VALUE = "__unset__";
export default new (_a = class {
        constructor() {
            _cache.set(this, new Map());
            __markFilters.set(this, void 0);
        }
        get(key) {
            var _a;
            let value = __classPrivateFieldGet(this, _cache, "f").get(key);
            if (value === UNSET_VALUE) {
                return undefined;
            }
            if (value === undefined) {
                value = (_a = document.querySelector(`[name="${key}"]`)) === null || _a === void 0 ? void 0 : _a.content;
                __classPrivateFieldGet(this, _cache, "f").set(key, value !== null && value !== void 0 ? value : UNSET_VALUE);
            }
            return value !== null && value !== void 0 ? value : undefined;
        }
        get markFilters() {
            var _a;
            if (__classPrivateFieldGet(this, __markFilters, "f") === undefined) {
                const newMarkFilters = new MarkFilters((_a = this.get('mark-filters')) !== null && _a !== void 0 ? _a : '0');
                __classPrivateFieldSet(this, __markFilters, newMarkFilters, "f");
                return newMarkFilters;
            }
            return __classPrivateFieldGet(this, __markFilters, "f");
        }
        get intlHourCycle() {
            const hourCycle = this.get('hour-cycle');
            if (hourCycle !== undefined) {
                return hourCycle === '24' ? 'h23' : 'h12';
            }
            return;
        }
    },
    _cache = new WeakMap(),
    __markFilters = new WeakMap(),
    _a);
